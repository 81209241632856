<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    width="80%"
    :title="imgUrl[index].name"
    custom-class="dialog_style"
    destroy-on-close
  >
    <div v-if="imgUrl[index].url.length != 0" style="text-align: center">
      <el-carousel
        trigger="click"
        :height="contentStyleObj"
        :autoplay="false"
        :initial-index="currentIndex"
        @change="changeHandle"
        ref="carousel"
      >
        <el-carousel-item
          v-for="(item, index) in imgUrl[index].url"
          :key="index"
        >
          <img
            :src="item.img"
            style="display: inline-block; max-width: 100%"
            :style="{ height: contentStyleObj }"
            class="sj_pic"
            @click="checkImage(item.img)"
          />
          <el-button
            @click="downloadImage(item.ycExcel)"
            size="small"
            type="primary"
            class="down_btn"
            v-if="item.ycExcel"
            icon="Folder"
            >导出错误数据</el-button
          >
          <el-button
            @click="downloadWszm(item.downImg)"
            size="small"
            type="primary"
            class="down_btn"
            v-if="item.downImg && printFrom"
            icon="Printer"
            >打印</el-button
          >
        </el-carousel-item>
      </el-carousel>
      <div class="pic_style">
        <div
          v-for="(item, index) in imgUrl[index].url"
          :key="index"
          :class="filterIcon(item.img) ? 'pic_image_box' : 'pic_box'"
          @click="checkEach(index)"
        >
          <i
            :class="filterIcon(item.img)"
            style="position: absolute; top: 3px; right: 4px"
          ></i>
          <img
            :src="item.img"
            style="width: 80px; height: 80px"
            :class="{ active: index == currentIndex }"
          />
          <span class="title" v-if="item.tableName">{{
            filterTable(item.tableName)
          }}</span>
          <span class="title">{{ filterText(item.img) }}</span>
        </div>
      </div>
    </div>
    <div v-else style="text-align: center">
      <img
        :style="{ height: contentStyleObj1 }"
        src="../../assets/noAvailable.png"
        alt=""
      />
    </div>
    <div class="bottom_com">
      <div>
        <div v-if="index != 0" @click="topClick" class="each">
          <el-icon><d-arrow-left /></el-icon>上一家：{{
            imgUrl[index - 1].name
          }}
        </div>
      </div>
      <div>
        <div v-if="index != allIndex - 1" @click="bottomClick" class="each">
          <el-icon><d-arrow-right /></el-icon>下一家：{{
            imgUrl[index + 1].name
          }}
        </div>
      </div>
    </div>
  </el-dialog>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
  />
</template>

<script>
import { adaptiveSize2A5PDF } from "@/api/printSet";
export default {
  name: "sbImgUrl",
  props: {
    printFrom: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgUrl: [
        {
          url: "",
          name: "",
        },
      ], //所有图片地址
      name: "", //公司名称
      dialogVisible: false,
      index: 0, //当前图片的索引
      allIndex: 0, //图片总数
      contentStyleObj: {}, //高度变化
      currentIndex: 0,
      contentStyleObj1: {},
      imgSrc: [],
      showImagePreview: false,
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(300);
    this.contentStyleObj1 = this.$getHeight(202);
  },
  methods: {
    dialog(imageAll, index, currentIndex) {
      this.allIndex = imageAll.length;
      this.index = index;
      this.imgUrl = imageAll;
      this.dialogVisible = true;
      this.currentIndex = currentIndex;
    },
    topClick() {
      this.currentIndex = 0;
      if (this.index > 0) {
        this.index--;
        return;
      }
      this.$message({
        message: "没有上一张了",
        type: "warning",
      });
    },
    bottomClick() {
      this.currentIndex = 0;
      if (this.index < this.allIndex - 1) {
        this.index++;
        return;
      }
      this.$message({
        message: "没有下一张了",
        type: "warning",
      });
    },
    checkEach(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    changeHandle(e) {
      this.currentIndex = e;
    },
    checkImage(img) {
      this.showImagePreview = true;
      this.imgSrc = [img];
    },
    downloadImage(img) {
      window.open('https://task.listensoft.net/' + img)
    },
    downloadWszm(item){
      let param = {
        markType: this.printFrom,
        imgPath: item,
      };
      adaptiveSize2A5PDF(param).then((res) => {
        if (res.data.msg == "success") {
          window.open("https://file.listensoft.net" + res.data.data.path);
        }
      });
    },
    filterBorder(row) {
      if (row.match("申报成功")) {
        return "border-color:#67c23a";
      } else if (row.match("检查失败")) {
        return "border-color:red";
      } else if (row.match("有遗漏") || row.match("有异常")) {
        return "border-color:#e6a23c";
      } else {
        return "border-color:#ffffff";
      }
    },
    filterIcon(row) {
      if (row.match("申报成功")) {
        return "iconfont icon-duihao";
      } else if (row.match("检查失败")) {
        return "iconfont icon-cuowu";
      } else if (row.match("有遗漏") || row.match("有异常")) {
        return "iconfont icon-gantanhao gantanhao-yc";
      } else {
        return "";
      }
    },
    filterText(row) {
      if (row.indexOf("新版") != -1) {
        let str = row.split("_");
        return str[1];
      } else {
        return "";
      }
    },
    filterTable(type) {
      if (type == "gs_vat") {
        return "增值税 - 一般";
      } else if (type == "gs_small_vat") {
        return "增值税 - 小规模";
      } else if (type == "gs_deed") {
        return "财产和行为税";
      } else if (type == "gs_tax_quarter") {
        return "企业所得税";
      } else if (type == "gs_gs") {
        return "个税";
      } else if (type == "gs_whsyjsfsbbygz") {
        return "文化事业建设税";
      } else if (type == "gs_xfs") {
        return "消费税";
      } else if (type == "gs_cbj") {
        return "残保金";
      } else if (type == "gs_fssr") {
        return "非税收入";
      } else if (type == "gs_sl") {
        return "水利";
      } else if (type == "gs_ljcl") {
        return "垃圾处置费";
      } else if (type == "gs_qtsr") {
        return "工会经费";
      } else if (type == "gs_gs_one") {
        return "个独(查账征收)";
      } else if (type == "gs_gs_hd") {
        return "个独(核定征收)";
      } else if (type == "gs_cwbb") {
        return "财务报表";
      } else if (type == "gs_tax_dqde") {
        return "个税(定期定额)";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.down_btn {
  position: absolute;
  right: 0;
}
.demo-image__preview {
  text-align: center;
}
.switchover {
  width: 20px;
  // height: 60px;
  text-align: center;
  .two_btn {
    font-size: 30px;
    color: #362721;
    line-height: 60px;
    background: #eee;
    cursor: pointer;
  }
  .arrow_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .arrow_right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.each {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.pic_style {
  width: 100%;
  margin: 0 auto;
  display: flex;
  // align-items: center;
  background-image: linear-gradient(
    180deg,
    rgba(23, 162, 184, 0.2) 0%,
    rgba(25, 182, 199, 0) 100%
  );
  padding: 2px 0;
  flex-wrap: wrap;
  //margin-top: 5px;
}
.bottom_com {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  span {
    cursor: pointer;
  }
}
.pic_box {
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  width: 84px;
  // height: 84px;
  img {
    border: 2px solid #efefef;
  }
}
.pic_image_box {
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  width: 84px;
  // height: 84px;
  img {
    border: 2px solid #efefef;
  }
}
.pic_image_box::before {
  content: ""; /* 创建伪元素 */
  position: absolute; /* 绝对定位 */
  top: 2px; /* 与正方形顶部对齐 */
  right: 2px; /* 与正方形右侧对齐 */
  width: 0;
  height: 0;
  border-width: 16px;
  border-style: dashed;
  border-color: #fff #fff transparent transparent; // 上 右 下 左
}
.active {
  border-color: #6aa2e7 !important;
}
.iconfont {
  font-size: 14px;
}
.sj_pic {
  padding: 5px;
  box-shadow: 0 2px 10px #ddd;
}
.title {
  // display:inline-block;
  width: 84px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-size: 11px;
}
</style>
<style>
.el-carousel__indicator.is-active button {
  background-color: #17a2b8;
}
.el-carousel__button {
  background-color: gray;
  height: 4px;
}
.dialog_style .el-dialog__body {
  padding: 15px !important;
}
</style>
